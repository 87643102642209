<template>
  <v-container>
    <v-tabs v-model="internalValue">
      <v-tab v-for="tab in tabList" v-bind:key="tab.id">
        {{$t(tab.name)}}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="internalValue"
       :transition="false"
       :reverse-transition="false"
      >
      <v-tab-item key="users"
       :transition="false"
       :reverse-transition="false">
        <UsersPane
          :users="users"
          :participants="participants"
          @loadUsers="loadUsers"
          @loadParticipants="loadParticipants"
        />
      </v-tab-item>
      <v-tab-item key="participants"
       :transition="false"
       :reverse-transition="false"
      >
        <ParticipantsPane
          :participants="participants"
          @loadParticipants="loadParticipants"
        />
      </v-tab-item>
      <v-tab-item key="folders"
       :transition="false"
       :reverse-transition="false"
      >
        <FoldersPane
          :folders="folders"
          @loadFolders="loadFolders"
        />
      </v-tab-item>
      <v-tab-item key="onetimeUrls"
       :transition="false"
       :reverse-transition="false"
      >
        <OnetimeUrlsPane
          :onetimeUrls="onetimeUrls"
          :folders="folders"
          @loadOnetimeUrls="loadOnetimeUrls"
          @loadFolders="loadFolders"
        />
      </v-tab-item>
      <v-tab-item key="participantsPaths"
       :transition="false"
       :reverse-transition="false"
      >
        <ParticipantPathsPane
          :participants="participants"
          :folders="folders"
          @loadParticipants="loadParticipants"
          @loadFolders="loadFolders"
        />
      </v-tab-item>
      <v-tab-item key="pathParticipants"
       :transition="false"
       :reverse-transition="false"
      >
        <PathParticipantsPane
          :participants="participants"
          :folders="folders"
          @loadParticipants="loadParticipants"
          @loadFolders="loadFolders"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
  import Proxyable from 'vuetify/lib/mixins/proxyable'

  import UsersPane from './admin/UsersPane';
  import ParticipantsPane from './admin/ParticipantsPane';
  import FoldersPane from './admin/FoldersPane';
  import OnetimeUrlsPane from './admin/OnetimeUrlsPane';
  import ParticipantPathsPane from './admin/ParticipantPathsPane';
  import PathParticipantsPane from './admin/PathParticipantsPane';

  export default Proxyable.extend({
    name: 'Administration',

    components: {
      UsersPane,
      ParticipantsPane,
      FoldersPane,
      OnetimeUrlsPane,
      ParticipantPathsPane,
      PathParticipantsPane,
    },
    
    data: () => ({
      tabList: [
        { id: "users", name: 'infex.admin.usersTab' },
        { id: "participants", name: 'infex.admin.participantsTab' },
        { id: "folders", name: 'infex.admin.foldersTab' },
        { id: "onetimeUrls", name: 'infex.admin.onetimeUrlsTab' },
        { id: "participantPaths", name: 'infex.admin.participantPathsTab' },
        { id: "pathParticipants", name: 'infex.admin.pathParticipantsTab' },
      ],
      users: [],
      usersLoaded: false,
      participants: [],
      participantsLoaded: false,
      folders: [],
      foldersLoaded: false,
      onetimeUrls: [],
      onetimeUrlsLoaded: false,
    }),

    methods: {
      loadAllObjects(field,op) {
        const log = this.$root.log;

        console.log("Calling "+op+"()...");
        this.$root.infexService[op]().then(
          (res) => {
            console.log(op+"() returned [",res,"]");
            
            this[field] = res;
            this[field+"Loaded"] = true;
          },
          (err) => {
            console.log(op+"() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:"getAllUsers()"}));
          }
        );
      },
      loadUsers(force) {
        if (force || !this.usersLoaded) {
          this.loadAllObjects("users","getAllUsers");
        }
      },
      loadParticipants(force) {
        if (force || !this.participantsLoaded) {
          this.loadAllObjects("participants","getAllParticipants");
        }
      },
      loadFolders(force) {
        if (force || !this.foldersLoaded) {
          this.loadAllObjects("folders","getAllFolders");
        }
      },
      loadOnetimeUrls(force) {
        if (force || !this.onetimeUrlsLoaded) {
          this.loadAllObjects("onetimeUrls","getAllOneTimeUrls");
        }
      },
    }
  })
</script>
