<template>
  <v-container>
    <v-select
     v-model="defaultItem.participantId"
     :items="participants"
      item-text="name"
      item-value="id"
      :return-object="false"
      :label="$t('infex.admin.participant')+'\u2026'"
      @input="loadPermissions"
    />

    <v-data-table
      dense
      disable-pagination
      :key="foldersMapKey"
      :headers="headers"
      :items="permissions"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.folderId="{ item }">
        <span>{{ formatFolderId(item.folderId) }}</span>
      </template>
      <template v-slot:item.readFlag="{ item }">
        <span>{{ (item.flags & perms.READ_FLAG) ? "\u2713" : "\u00a0" }}</span>
      </template>
      <template v-slot:item.writeFlag="{ item }">
        <span>{{ (item.flags & perms.WRITE_FLAG) ? "\u2713" : "\u00a0" }}</span>
      </template>
      <template v-slot:item.deleteFlag="{ item }">
        <span>{{ (item.flags & perms.DELETE_FLAG) ? "\u2713" : "\u00a0" }}</span>
      </template>
      <template v-slot:item.readNotificationFlag="{ item }">
        <span>{{ (item.flags & perms.READ_NOTIFICATION_FLAG) ? "\u2713" : "\u00a0" }}</span>
      </template>
      <template v-slot:item.writeNotificationFlag="{ item }">
        <span>{{ (item.flags & perms.WRITE_NOTIFICATION_FLAG) ? "\u2713" : "\u00a0" }}</span>
      </template>
      <template v-slot:item.onetimeUrlFlag="{ item }">
        <span>{{ (item.flags & perms.GENERATE_ONETIMEURLS_FLAG) ? "\u2713" : "\u00a0" }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="editItem(item)"
          color="black"
          :title="$t('infex.admin.editPermission')+'\u2026'"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          color="black"
          :title="$t('infex.admin.deletePermission')+'\u2026'"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:body.append>
        <tr>
          <td colspan="8" class="text-right">
            <v-btn
              color="primary"
              dark
              @click="addItem()"
              :disabled="defaultItem.participantId==null"
            >
              {{ $t('infex.admin.addPermission') }}
              <v-icon small class="ml-2">
                mdi-key-plus
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{$t('infex.deleteConfirmation')}}</v-card-title>
        <v-card-text>
          {{$t('infex.admin.reallyDeletePermission',[formatFolderId(editedItem.folderId)])}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{$t('infex.cancel')}}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDelete">{{$t('infex.ok')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogEdit"
        max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(editedFolderId == null ? 'infex.admin.addPermission' : 'infex.admin.editPermission') }}</span>
        </v-card-title>

        <v-card-text>
          <v-form v-model="dialogEditValid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.folderId"
                  :items="activeFolders"
                  item-text="displayName"
                  item-value="id"
                  :return-object="false"
                  :label="$t('infex.folder')+'\u2026'"
                  :rules="[rules.required]"
                  :disabled="editedFolderId != null"
                />
              </v-col>

              <v-col cols="6" md="4">
                <v-switch v-model="mayRead" :label="$t('infex.permissions.R')"/>
              </v-col>
              <v-col cols="6" md="4">
                <v-switch v-model="mayWrite" :label="$t('infex.permissions.W')"/>
              </v-col>
              <v-col cols="6" md="4">
                <v-switch v-model="mayDelete" :label="$t('infex.permissions.D')"/>
              </v-col>
              <v-col cols="6" md="4">
                <v-switch v-model="hasReadNotification" :label="$t('infex.permissions.RN')"/>
              </v-col>
              <v-col cols="6" md="4">
                <v-switch v-model="hasWriteNotification" :label="$t('infex.permissions.WN')"/>
              </v-col>
              <v-col cols="6" md="4">
                <v-switch v-model="mayGenerateOnetimeUrl" :label="$t('infex.permissions.OT')"/>
              </v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeEdit"
          >
            {{$t('infex.cancel')}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmEdit"
            :disabled="!dialogEditValid || editedItem.flags==0"
          >
            {{$t('infex.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import * as perms from '../../helpers/InfexPermissions';
  import {makeInputRules,makeMap} from '../../lib/Util'

  export default {
    name: 'ParticipantPathsPane',

    props: {
      participants: {
        type: Array,
        default: () => []
      },
      folders: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      mayRead: {
        get: function() {
          return (this.editedItem.flags & perms.READ_FLAG) != 0;
        },
        set: function(v) {
          if (v) {
            this.editedItem.flags |= perms.READ_FLAG;
          }
          else {
            this.editedItem.flags &= ~perms.READ_FLAG;
          }
        }
      },
      mayWrite: {
        get: function() {
          return (this.editedItem.flags & perms.WRITE_FLAG) != 0;
        },
        set: function(v) {
          if (v) {
            this.editedItem.flags |= perms.WRITE_FLAG;
          }
          else {
            this.editedItem.flags &= ~perms.WRITE_FLAG;
          }
        }
      },
      mayDelete: {
        get: function() {
          return (this.editedItem.flags & perms.DELETE_FLAG) != 0;
        },
        set: function(v) {
          if (v) {
            this.editedItem.flags |= perms.DELETE_FLAG;
          }
          else {
            this.editedItem.flags &= ~perms.DELETE_FLAG;
          }
        }
      },
      hasReadNotification: {
        get: function() {
          return (this.editedItem.flags & perms.READ_NOTIFICATION_FLAG) != 0;
        },
        set: function(v) {
          if (v) {
            this.editedItem.flags |= perms.READ_NOTIFICATION_FLAG;
          }
          else {
            this.editedItem.flags &= ~perms.READ_NOTIFICATION_FLAG;
          }
        }
      },
      hasWriteNotification: {
        get: function() {
          return (this.editedItem.flags & perms.WRITE_NOTIFICATION_FLAG) != 0;
        },
        set: function(v) {
          if (v) {
            this.editedItem.flags |= perms.WRITE_NOTIFICATION_FLAG;
          }
          else {
            this.editedItem.flags &= ~perms.WRITE_NOTIFICATION_FLAG;
          }
        }
      },
      mayGenerateOnetimeUrl: {
        get: function() {
          return (this.editedItem.flags & perms.GENERATE_ONETIMEURLS_FLAG) != 0;
        },
        set: function(v) {
          if (v) {
            this.editedItem.flags |= perms.GENERATE_ONETIMEURLS_FLAG;
          }
          else {
            this.editedItem.flags &= ~perms.GENERATE_ONETIMEURLS_FLAG;
          }
        }
      },
      activeFolders: function() {
        const usedFolders = {};
        for (let i=0;i<this.permissions.length;++i) {
            const p = this.permissions[i];
            if (p.folderId != this.editedFolderId) {
               usedFolders[p.folderId] = true;
            }
        }
        const ret = [];
        for (let i=0;i<this.folders.length;++i) {
            const f = this.folders[i];
            ret.push({ id: f.id, displayName: f.displayName, disabled: !!usedFolders[f.id] });
        }
        return ret;
      }
    },

    watch: {
      folders(val) {
        this.foldersMap=makeMap(val);
        // fore to re-render the list.
        ++this.foldersMapKey;
        console.log("ParticipantPathsPane: folders changed [",this.foldersMapKey,"]");
      },
      dialogEdit (val) {
        val || this.closeEdit()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    data: function() { return {
      headers: [
        {
          text: this.$t('infex.folder'),
          align: 'start',
          value: 'folderId',
        },
        { text: 'R', value: 'readFlag' },
        { text: 'W', value: 'writeFlag' },
        { text: 'D', value: 'deleteFlag' },
        { text: 'RN', value: 'readNotificationFlag' },
        { text: 'WN', value: 'writeNotificationFlag' },
        { text: 'OT', value: 'onetimeUrlFlag' },
        { text: this.$t('infex.actions'), value: 'actions', sortable: false },
      ],
      permissions: [],
      foldersMap: {},
      foldersMapKey: 0,
      editedItem: {
        participantId: null,
        folderId: null,
        flags: 0,
      },
      editedFolderId: null,
      defaultItem: {
        participantId: null,
        folderId: null,
        flags: 0,
      },
      dialogEdit: false,
      dialogEditValid: false,
      dialogDelete: false,
      perms,
      rules: makeInputRules(this),
    }},

    mounted: function() {
      this.$emit("loadFolders",false);
      this.$emit("loadParticipants",false);
      this.foldersMap=makeMap(this.folders);
    },

    methods: {
      loadPermissions(participantId) {
        console.log("Loading permission for participant [",participantId,"]");
        const log = this.$root.log;

        console.log("Calling getAllPermissionsForParticipant()...");
        this.$root.infexService.getAllPermissionsForParticipant(participantId).then(
          (res) => {
            console.log("getAllPermissionsForParticipant() returned [",res,"]");
            
            this.permissions = res;
          },
          (err) => {
            console.log("getAllPermissionsForParticipant() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:"getAllPermissionsForParticipant()"}));
          }
        );
      },

      formatFolderId(folderId) {
        if (folderId == null) {
          return '\u2014';
        }
        else {
          const f = this.foldersMap[folderId];
          if (f == null) {
            return "#" + folderId;
          }
          else {
            return f.displayName;
          }
        }
      },

      editItem (item) {
        // folder to retain in drop box.
        this.editedFolderId = item.folderId;
        this.editedItem = Object.assign({}, item)
        this.dialogEdit = true
      },

      addItem () {
        // no folder to retain in drop box.
        this.editedFolderId = null;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialogEdit = true
      },

      confirmEdit () {

        const log = this.$root.log;

        const op = this.editedFolderId == null ? "insertPermission" : "updatePermission"

        this.$root.infexService[op](this.editedItem).then(
          (res) => {
            console.log("FoldersPane: "+op+"() returned [",res,"]");

            this.$nextTick(() => this.loadPermissions(this.defaultItem.participantId));
          },
          (err) => {
            console.log("FoldersPane: "+op+"() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:op+"()"}));
          }
        );

        this.closeEdit()
      },

      closeEdit () {
        this.dialogEdit = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },

      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      confirmDelete () {

        const log = this.$root.log;

        this.$root.infexService.deletePermission(this.editedItem).then(
          (res) => {
            console.log("FoldersPane: deletePermission() returned [",res,"]");

            this.$nextTick(() => this.loadPermissions(this.defaultItem.participantId));
          },
          (err) => {
            console.log("ParticipantPathsPane: deletePermission() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:"deletePermission()"}));
          }
        );

        this.closeDelete()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },
      
    }
  }
</script>
