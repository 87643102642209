<template>
  <v-container>
    <v-data-table
      dense
      :key="foldersMapKey"
      :headers="headers"
      :items="onetimeUrls"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.expiresUtcSecs="{ item }">
        <span>{{ formatTimestamp(new Date(item.expiresUtcSecs))}}</span>
      </template>
      <template v-slot:item.folderId="{ item }">
        <span>{{ formatFolderId(item.folderId) }}</span>
      </template>
      <template v-slot:item.deleteExpiredFile="{ item }">
        <span>{{ item.deleteExpiredFile ? "\u2713" : "\u00a0" }}</span>
      </template>
      <template v-slot:item.deleteDownloadedFile="{ item }">
        <span>{{ item.deleteDownloadedFile ? "\u2713" : "\u00a0" }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="editItem(item)"
          color="black"
          :title="$t('infex.admin.editOnetimeUrl')+'\u2026'"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          color="black"
          :title="$t('infex.admin.deleteOnetimeUrl')+'\u2026'"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:body.append>
        <tr>
          <td class="text-start">
            <v-btn icon small @click="refresh" :title="$t('infex.refresh')+'\u2026'">
              <v-icon small color="black">mdi-refresh</v-icon>
            </v-btn>
          </td>
          <td colspan="9" class="text-right">
            <v-btn
              color="primary"
              dark
              @click="addItem()"
            >
              {{ $t('infex.admin.addOnetimeUrl') }}
              <v-icon
               small
               class="ml-2"
              >
                mdi-account-plus
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{$t('infex.deleteConfirmation')}}</v-card-title>
        <v-card-text>
          {{$t('infex.admin.reallyDeleteOnetimeUrl',[editedItem.fileName])}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{$t('infex.cancel')}}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDelete">{{$t('infex.ok')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogEdit"
        max-width="1200px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(editedItem.uuid == null ? 'infex.admin.addOnetimeUrl' : 'infex.admin.editOnetimeUrl') }}</span>
        </v-card-title>

        <v-card-text>
          <v-form v-model="dialogEditValid">
          <v-container>
            <v-row>
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="editedItem.fileName"
                  :label="$t('infex.filename')"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col md="6" cols="12">
                <v-select
                  v-model="editedItem.folderId"
                  :items="folders"
                  item-text="displayName"
                  item-value="id"
                  :return-object="false"
                  :label="$t('infex.folder')"
                />
              </v-col>
              
              <v-col md="6" cols="12">
                <v-text-field
                  v-model="editedItem.recipientEmailAdr"
                  :label="$t('infex.email')"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>

              <v-col cols="6" md="3">
                <v-menu
                  v-model="menuExpiresUtcDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                 min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expiresUtcDate"
                      :label="$t('infex.expiry')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="expiresUtcDate"
                    @input="menuExpiresUtcDate = false"
                  />
                </v-menu>
              </v-col>

              <v-col cols="6" md="3">
                <v-menu
                  ref="menuExpiresUtcTime"
                  v-model="menuExpiresUtcTime"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="expiresUtcTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expiresUtcTime"
                      :label="$t('infex.expiryTime')"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-time-picker
                    v-if="menuExpiresUtcTime"
                    v-model="expiresUtcTime"
                    use-seconds
                    full-width
                    @click:second="$refs.menuExpiresUtcTime.save(expiresUtcTime)"
                  />
                </v-menu>
              </v-col>
              <v-col cols="6" md="3">
                <v-text-field
                    type="number"
                    v-model.number="editedItem.maxDownloads"
                    :label="$t('infex.maxDownloads')"
                    :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="6" md="3">
                <v-text-field
                    type="number"
                    v-model.number="editedItem.downloadCount"
                    :label="$t('infex.downloadCount')"
                />
              </v-col>
              <v-col cols="6" md="3">
                <v-switch
                    v-model="editedItem.deleteExpiredFile"
                    :label="$t('infex.deleteExpiredFile')"
                />
              </v-col>
              <v-col cols="6" md="3">
                <v-switch
                    v-model="editedItem.deleteDownloadedFile"
                    :label="$t('infex.deleteDownloadedFile')"
                />
              </v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeEdit"
          >
            {{$t('infex.cancel')}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmEdit"
            :disabled="!dialogEditValid"
          >
            {{$t('infex.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import {makeInputRules,formatTimestamp,formatDate,formatTime,parseDate,parseTime,makeMap} from '../../lib/Util'

  export default {
    name: 'OnetimeUrlsPane',

    props: {
      onetimeUrls: {
        type: Array,
        default: () => []
      },
      folders: {
        type: Array,
        default: () => []
      },
    },

/*
	private String uuid;
	private Long folderId;
	private String fileName;
	private String recipientEmailAdr;
	private Integer maxDownloads;
	private Integer downloadCount;
	private Long expiresUtcSecs;
	private boolean deleteExpiredFile;
	private boolean deleteDownloadedFile;
	private Long deletedUtcSecs;
*/

    data: function() { return {
      headers: [
        {
          text: 'UUID',
          align: 'start',
          value: 'uuid',
        },
        {
          text: this.$t('infex.folder'),
          align: 'start',
          value: 'folderId',
        },
        {
          text: this.$t('infex.filename'),
          align: 'start',
          value: 'fileName',
        },
        {
          text: this.$t('infex.email'),
          align: 'start',
          value: 'recipientEmailAdr',
        },
        {
          text: this.$t('infex.expiry'),
          align: 'start',
          value: 'expiresUtcSecs',
        },
        {
          text: this.$t('infex.downloadCount'),
          align: 'start',
          value: 'downloadCount',
          sortable: false,
        },
        {
          text: this.$t('infex.maxDownloads'),
          align: 'start',
          value: 'maxDownloads',
          sortable: false,
        },
        {
          text: this.$t('infex.deleteExpiredFile'),
          align: 'start',
          value: 'deleteExpiredFile',
          sortable: false,
        },
        {
          text: this.$t('infex.deleteDownloadedFile'),
          align: 'start',
          value: 'deleteDownloadedFile',
          sortable: false,
        },
        { text: this.$t('infex.actions'), value: 'actions', sortable: false },
      ],
      editedItem: {
        uuid: '',
        folderId: -1,
        fileName: '',
        recipientEmailAdr: '',
        maxDownloads: 0,
        downloadCount: 0,
        expiresUtcSecs:  new Date().getTime(),
        deleteExpiredFile: false,
        deleteDownloadedFile: false,
        deletedUtcSecs: 0
      },
      defaultItem: {
        uuid: '',
        folderId: -1,
        fileName: '',
        recipientEmailAdr: '',
        maxDownloads: 0,
        downloadCount: 0,
        expiresUtcSecs: new Date().getTime(),
        deleteExpiredFile: false,
        deleteDownloadedFile: false,
        deletedUtcSecs: 0
      },
      dialogEdit: false,
      dialogEditValid: false,
      dialogDelete: false,
      menuExpiresUtcDate: false,
      menuExpiresUtcTime: false,
      rules: makeInputRules(this),
      foldersMap: {},
      foldersMapKey: 0,
    }},

    computed: {
      expiresUtcDate: {
        get: function() {
           return formatDate(new Date(this.editedItem.expiresUtcSecs));
        },
        set: function(v) {
           const d= new Date(this.editedItem.expiresUtcSecs);
           const ymd = parseDate(v);
           this.editedItem.expiresUtcSecs = new Date(ymd.year,ymd.month-1,ymd.day,d.getHours(),d.getMinutes(),d.getSeconds()).getTime();
        }
      },
      expiresUtcTime: {
        get: function() {
           return formatTime(new Date(this.editedItem.expiresUtcSecs));
        },
        set: function(v) {
           const d= new Date(this.editedItem.expiresUtcSecs);
           const hms = parseTime(v);
           this.editedItem.expiresUtcSecs = new Date(d.getYear()+1900,d.getMonth(),d.getDate(),hms.hours,hms.minutes,hms.seconds).getTime();
        }
      }
    },

    watch: {
      folders(val) {
        this.foldersMap=makeMap(val);
        ++this.foldersMapKey;
        console.log("OnetimeUrlsPane: folders changed [",this.foldersMapKey,"]");
      },
      dialogEdit (val) {
        val || this.closeEdit()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted: function() {
      this.$emit("loadOnetimeUrls",false);
      this.$emit("loadFolders",false);
      this.foldersMap=makeMap(this.folders);
    },

    methods: {

      formatTimestamp,

      refresh() {
        this.$emit("loadOnetimeUrls",true);
      },

      formatFolderId(folderId) {
        if (folderId == null) {
          return '\u2014';
        }
        else {
          const f = this.foldersMap[folderId];
          if (f == null) {
            return "#" + folderId;
          }
          else {
            return f.displayName;
          }
        }
      },
      
      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogEdit = true
      },

      addItem () {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialogEdit = true
      },

      confirmEdit () {

        const log = this.$root.log;

        const op = this.editedItem.uuid == null ? "insertOneTimeUrl" : "updateOneTimeUrl"

        this.$root.infexService[op](this.editedItem).then(
          (res) => {
            console.log("OnetimeUrlsPane: "+op+"() returned [",res,"]");

            this.$nextTick(() => this.$emit("loadOnetimeUrls",true));
          },
          (err) => {
            console.log("OnetimeUrlsPane: "+op+"() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:op+"()"}));
          }
        );

        this.closeEdit()
      },

      closeEdit () {
        this.dialogEdit = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },

      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      confirmDelete () {

        const log = this.$root.log;

        this.$root.infexService.deleteOneTimeUrl(this.editedItem.uuid).then(
          (res) => {
            console.log("ParticipantsPane: deleteParticipant() returned [",res,"]");

            this.$nextTick(() => this.$emit("loadOnetimeUrls",true));
          },
          (err) => {
            console.log("ParticipantsPane: deleteOneTimeUrl() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:"deleteOneTimeUrl()"}));
          }
        );

        this.closeDelete()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },
    }
  }
</script>
