<style>
.infexMonospace {
  font-family: monospace;
}
</style>

<template>
  <v-container
    @dragover.stop.prevent="dragActive=1"
    @dragenter.stop.prevent="dragActive=1"
    @dragleave.stop.prevent="dragActive=0"
    @dragend.stop.prevent="dragActive=0"
    @drag.stop.prevent
    @drop.stop.prevent="filesDropped"
    :style="{borderStyle:  dragActive==1 ? 'dashed' : 'none', borderRadius: '5px', borderWidth: '1px' }"
  >
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-1 font-weight-bold mb-2">
          {{folder.displayName}}
        </h1>
      </v-col>
    </v-row>

    <v-card v-if="mayUpload" outlined class="pa-2 mb-2 pr-4" :class="{lime: dragActive==1, 'red': dragActive==2, 'accent-1': dragActive==2}"
     >
       <v-file-input
         ref="uploadFiles"
         :value="droppedFiles"
         multiple
         :label="$t('infex.dragYourFiles')"
         @change="filesSelected"/>
       <v-container v-if="uploadProgresses != null">
         <div class="d-flex flex-nowrap align-end" v-for="uploadProgress in uploadProgresses" v-bind:key="uploadProgress.id">
           <v-icon class="mr-2">
             {{iconsForState[uploadProgress.state]}}
           </v-icon>
           <div class="flex-grow-1">
             <div>{{uploadProgress.filename}}</div>
             <v-progress-linear :value="uploadProgress.progress"/>
           </div>
         </div>
       </v-container>
    </v-card>
    <v-data-table
      dense
      disable-pagination
      hide-default-footer
      :headers="headers"
      :items="files"
      item-key="name"
      class="elevation-1"
    >
      <template v-slot:item.timestamp="{ item }">
        <span>{{ formatTimestamp(new Date(item.timestamp))}}</span>
      </template>
      <template v-slot:item.size="{ item }">
        <span>{{ formatSize(item.size)}}</span>
      </template>
      <template v-slot:item.filename="{ item }">
        <a :href="'/infex/infexFile/'+folder.id+'/'+encodeURIComponent(item.filename)" target="_blank">{{item.filename}}</a>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="mayDelete"
          small
          color="black"
          @click="deleteItem(item)"
          :title="$t('infex.fileDeleteTitle',[item.filename])"
        >
          mdi-delete
        </v-icon>
        <v-icon
          v-if="mayGenerateOnetimeUrls"
          small
          color="black"
          @click="generateOnetimeUrl(item)"
          :title="$t('infex.fileSendTitle',[item.filename])"
        >
          mdi-file-send-outline
        </v-icon>
        <v-icon
          v-if="mayShowInline(item.mimeType)"
          small
          color="black"
          @click="showInline(item)"
          :title="$t('infex.fileShowTitle',[item.filename])"
        >
          mdi-open-in-new
        </v-icon>
      </template>
      <template v-slot:body.append="{}">
        <tr>
          <td class="text-start">{{ $tc('infex.totalFiles',files.length,{n:files.length}) }}</td>
          <td class="text-start">{{ $t('infex.totalSize',[formatSize(totalSize)])}}</td>
          <td class="text-start">{{ $t('infex.remainingSize',[formatSize(folder.totalMB*1024*1024-totalSize)])}}</td>
           <td class="text-start">
             <v-btn icon small :title="$t('infex.refresh')+'\u2026'" @click="loadFiles">
               <v-icon small color="black">mdi-refresh</v-icon>
             </v-btn>
           </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{$t('infex.deleteConfirmation')}}</v-card-title>
        <v-card-text>
          {{$t('infex.reallyDeleteFile',[editedItem.filename])}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{$t('infex.cancel')}}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t('infex.ok')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog
        v-model="dialogOt"
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('infex.admin.addOnetimeUrl') }}</span>
        </v-card-title>

        <v-card-text>
          <v-form v-model="dialogOtValid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <span>{{otItem.fileName}}</span>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="otItem.recipientEmailAdr"
                  :label="$t('infex.email')"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  v-model="menuExpiresUtcDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                 min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expiresUtcDate"
                      :label="$t('infex.expiry')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="expiresUtcDate"
                    @input="menuExpiresUtcDate = false"
                  />
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                    type="number"
                    v-model.number="otItem.maxDownloads"
                    :label="$t('infex.maxDownloads')"
                    :rules="[rules.required]"
                />
              </v-col>

              <v-col cols="12" md="6">
                <v-switch
                    v-model="otItem.deleteExpiredFile"
                    :label="$t('infex.deleteExpiredFile')"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                    v-model="otItem.deleteDownloadedFile"
                    :label="$t('infex.deleteDownloadedFile')"
                />
              </v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogOt = false"
          >
            {{$t('infex.cancel')}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmOt"
            :disabled="!dialogOtValid"
          >
            {{$t('infex.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditInline">
      <v-card>
        <v-card-title class="headline">{{$t('infex.editTextFile',[editedItem.filename])}}</v-card-title>
        <v-textarea v-model="editInlineContent" solo rows="20" class="infexMonospace"/>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditInline">{{$t('infex.cancel')}}</v-btn>
          <v-btn color="blue darken-1" text @click="editInlineConfirm">{{$t('infex.ok')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import * as perms from '../helpers/InfexPermissions';
  import { makeInputRules,formatTimestamp,parseDate,formatDate } from '../lib/Util';
  import reloginFetch from '../lib/ReloginFetch'

  export default {
    name: 'Folder',

    props: {
      folder: {
        type: Object,
        required: true
      },
      refreshCount: {
        type: Number,
        required: true
      }
    },

    data: function() { return {
      headers: [
        {
          text: this.$t('infex.filename'),
          align: 'start',
          value: 'filename',
        },
        { text: this.$t('infex.filesize'), value: 'size' },
        { text: this.$t('infex.filedate'), value: 'timestamp' },
        { text: this.$t('infex.actions'), value: 'actions', sortable: false },
      ],
      files: [],
      totalSize: 0,
      dialogOt: false,
      dialogOtValid: false,
      menuExpiresUtcDate: false,
      otItem: {
        uuid: '',
        folderId: -1,
        fileName: '',
        recipientEmailAdr: '',
        maxDownloads: 0,
        downloadCount: 0,
        expiresUtcSecs:  new Date().getTime(),
        deleteExpiredFile: false,
        deleteDownloadedFile: false,
        deletedUtcSecs: null
      },
      defaultOtItem: {
        uuid: '',
        recipientEmailAdr: '',
        maxDownloads: 0,
        downloadCount: 0,
        expiresUtcSecs: new Date().getTime(),
        deleteExpiredFile: false,
        deleteDownloadedFile: false,
        deletedUtcSecs: null
      },
      dialogDelete: false,
      editedItem: {
        filename: '',
        size: 0,
        timestamp: 0,
        mimeType: ''
      },
      defaultItem: {
        filename: '',
        size: 0,
        timestamp: 0,
        mimeType: ''
      },
      dialogEditInline: false,
      editInlineContent: '',
      dragActive: 0,
      droppedFiles: null,
      uploadProgresses: null,
      lastUploadProgressId: 0,
      currentUpload: -1,
      uploadToken: null,
      uploadAbortController: null,
      iconsForState: {
        pending: "mdi-circle-outline",
        uploading: "mdi-progress-upload",
        failed: "mdi-alert-circle",
        finished: "mdi-checkbox-marked-circle-outline"
      },
      rules: makeInputRules(this),
    }},

    computed: {
      expiresUtcDate: {
        get: function() {
           return formatDate(new Date(this.otItem.expiresUtcSecs));
        },
        set: function(v) {
           const ymd = parseDate(v);
           this.otItem.expiresUtcSecs = new Date(ymd.year,ymd.month-1,ymd.day).getTime();
        }
      },
      mayDelete() {
        return (this.folder.flags&perms.DELETE_FLAG)!=0;
      },
      mayUpload() {
        return (this.folder.flags&perms.WRITE_FLAG)!=0;
      },
      mayGenerateOnetimeUrls() {
        return (this.folder.flags&perms.GENERATE_ONETIMEURLS_FLAG)!=0;
      }
    },

    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },
      dialogEditInline (val) {
        val || this.closeEditInline()
      },
      refreshCount(val) {
        console.log("Refreshing folder [",this.folder.id,"] with count [",val,"].");
        this.loadFiles();
      }
    },

    mounted: function() {
      console.log("Folder [",this.folder.id,"] mounted.");
      this.loadFiles();
    },

    methods: {
      formatTimestamp,
      loadFiles() {
        const log = this.$root.log;
        log.info(this.$t('infex.loadingFiles',{folder:this.folder.displayName}));

        this.$root.infexService.listFiles(this.folder.id).then(
          (res) => {
            log.ok(this.$tc('infex.successfullyLoadedFiles',res.length,{n:res.length,folder:this.folder.displayName}));
            console.log("listFiles() returned [",res,"]");
            
            this.files = res;
            this.totalSize = this.files.reduce((total,item) => total + item.size, 0);

            if (this.uploadAbortController != null &&
                this.uploadProgresses != null &&
                this.currentUpload > 0 &&
                this.currentUpload < this.uploadProgresses.length) {
              this.startNextUpload();
            }

          },
          (err) => {
            console.log("listFiles() failed:",err);
            log.error(this.$t('infex.loadingFilesFailed',{folder:this.folder.displayName}));
            this.uploadAbortController == null;
            this.currentUpload = -1;
          }
        );
      },
      formatSize(bytes) {
        if (bytes > 1073741824) {
          return (bytes/1073741824).toLocaleString(this.$i18n.locale,{maximumFractionDigits: 1}) +" GB";
        }
        if (bytes > 1048576) {
          return (bytes/1048576).toLocaleString(this.$i18n.locale,{maximumFractionDigits: 1}) +" MB";
        }
        if (bytes > 1024) {
          return (bytes/1024).toLocaleString(this.$i18n.locale,{maximumFractionDigits: 1}) +" kB";
        }
        return bytes+" Bytes";
      },
      mayShowInline(mimeType) {
        return mimeType == "application/pdf" || mimeType == "text/plain";
      },
      showInline(item) {
        console.log("Showing file [",item.filename,"] inline.");

        const url = '/infex/infexFile/'+this.folder.id+'/'+encodeURIComponent(item.filename)+'?inline=true';

        if (item.mimeType == "application/pdf") {
          window.open(url);
        }
        else {
          const nrUrl = url+"&noredir=true";
          const log = this.$root.log;

          log.info(this.$t('infex.loadingTextFile',[item.filename]));

          reloginFetch(nrUrl,{reloginHandler:this.$root.reloginHandler,cache:"no-store"}).then(
            (resp) => {

              if (resp.status == 200) {
                resp.text().then(
                  (txt) => {
                    log.ok(this.$t('infex.successfullyLoadedTextFile',[item.filename]));
                    this.editInlineContent = txt;
                    this.editedItem = Object.assign({}, item);
                    this.dialogEditInline = true;
                  }
                )
              }
              else {
                console.log("Fetching [",nrUrl,"] failed with HTTP error [",resp.status,"]");
                log.error(this.$t('infex.loadingTextFileFailed',[item.filename]));
              }
            },
            (err) => {
              console.log("Fetching [",nrUrl,"] failed:",err);
              log.error(this.$t('infex.loadingTextFileFailed',[item.filename]));
            }
          );
        }
      },
      generateOnetimeUrl(item) {
        console.log("Generating One-Time URL for file [",item.filename,"]");
        this.otItem = Object.assign({
            folderId:this.folder.id,
            fileName:item.filename,
            
          },
          this.defaultOtItem
        );
        this.dialogOt = true;
      },
      confirmOt () {

        const log = this.$root.log;

        console.log("Confirming One-Time URL [",this.otItem,"]");

        this.$root.infexService.insertOneTimeUrl(this.otItem).then(
          (res) => {
            console.log("Folder: insertOneTimeUrl() returned [",res,"]");

            this.dialogOt = false;
            this.$nextTick(this.loadFiles);
          },
          (err) => {
            console.log("Folder: insertOneTimeUrl() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:"insertOneTimeUrl()"}));
          }
        );

      },
      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {

        const log = this.$root.log;

        const filename = this.editedItem.filename;

        log.info(this.$t('infex.deletingFile',{folder:this.folder.displayName,file:filename}));

        this.$root.infexService.deleteFile(this.folder.id,filename).then(
          (res) => {
            log.ok(this.$t('infex.successfullyDeletedFile',{folder:this.folder.displayName,file:filename}));
            console.log("deleteFile() returned [",res,"]");

            this.$nextTick(this.loadFiles);
          },
          (err) => {
            console.log("deleteFile() failed:",err);
            log.error(this.$t('infex.deletingFileFailed',{folder:this.folder.displayName,file:filename}));
          }
        );

        this.closeDelete()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },
      editInlineConfirm() {

        console.log("Confirmed editing of file [",this.editedItem.filename,"]");

        // currentUpload is positive, if uploads are running.
        // in this case, simply add the new upload of the save content
        if (this.currentUpload < 0) {
          this.uploadProgresses = [];
        }

        this.uploadProgresses.push({
          id:++this.lastUploadProgressId,
          file:new Blob([this.editInlineContent]),
          filename:this.editedItem.filename,
          progress:0,
          state:"pending"
        });
        
        if (this.currentUpload < 0) {
          this.initiateUpload();
        }

        this.closeEditInline();
      },
      closeEditInline () {
        this.dialogEditInline = false
        
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editInlineContent = '';
        })
      },
      formatFileNames(files) {

        if (files) {
          if (files.length == 1) {
            return files[0].name;
          }
          else if (files.length > 1) {
            return this.$t('$vuetify.fileInput.counter',[files.length]);
          }
        }
        return '';
      },
      showFileNames(files) {
        var txt = this.$refs.uploadFiles.$el.getElementsByClassName("v-file-input__text");

        if (txt.length > 0) {
          txt[0].textContent = this.formatFileNames(files);
        }
      },
      filesDropped(event) {
        this.dragActive=0;
        this.droppedFiles=event.dataTransfer.files;
        console.log("Files dropped to upload:",this.droppedFiles);
        this.showFileNames(this.droppedFiles);
        if (this.droppedFiles && this.droppedFiles.length > 0) {
          this.startUpload(this.droppedFiles);
        }
      },
      filesSelected(uploads) {
        this.dragActive=0;
        this.droppedFiles = null;
        console.log("Files selected to upload:",uploads);
        this.showFileNames(uploads);
        if (uploads == null || uploads.length == 0) {

          if (this.uploadAbortController != null) {
            console.log("Aborting running upload in folder [",this.folder.displayName,"].");
            this.uploadAbortController.abort();
          }
          else {
            this.uploadProgresses = null;
            this.currentUpload = -1;
            this.uploadAbortController = null;
          }        
        }
        else {
          this.startUpload(uploads);
        }
      },
      startUpload(files) {

        const log = this.$root.log;

        const maxSize = this.folder.individualMB*1024*1024;

        let uploadSize = 0;
        for (let i=0;i<files.length;++i) {
          if (files[i].size > maxSize) {
            log.error(this.$t('infex.uploadedFileTooLarge',
              {size:this.formatSize(files[i].size),maxSize:this.formatSize(maxSize),file:files[i].name,folder:this.folder.displayName}));
            this.uploadProgresses = null;
            this.dragActive=2;
            return;
          }

          uploadSize += files[i].size;
        }

        const formattedFiles = this.formatFileNames(files);

        const remaining = this.folder.totalMB*1024*1024 - this.totalSize;
        
        if (uploadSize > remaining) {
          log.error(this.$tc('infex.uploadedFilesTooLarge',files.length,
            {size:this.formatSize(uploadSize),remaining:this.formatSize(remaining),files:formattedFiles,folder:this.folder.displayName}));
          this.uploadProgresses = null;
          this.dragActive=2;
          return;
        }

        if (this.currentUpload < 0) {
          this.uploadProgresses = [];
        }
        
        for (let i=0;i<files.length;++i) {
          this.uploadProgresses.push({
            id:++this.lastUploadProgressId,
            file:files[i],
            filename:files[i].name,
            progress:0,
            state:"pending"
          }); 
        }
        if (this.currentUpload < 0) {
          this.initiateUpload();
        }
      },
      initiateUpload() {
      
        const log = this.$root.log;
        this.currentUpload = 0;

        log.info(this.$t('infex.checkingFolder',{folder:this.folder.displayName}));

        this.$root.infexService.checkUploadFolder(this.folder.id).then(
          (res) => {
            log.ok(this.$t('infex.successfullyCheckedFolder',{folder:this.folder.displayName}));
            console.log("checkUploadFolder() returned [",res,"]");
            this.uploadToken = res;
            this.startNextUpload();
          },
          (err) => {
            console.log("checkUploadFolder() failed:",err);
            log.error(this.$t('infex.checkingFolderFailed',{folder:this.folder.displayName}));
          }
        );
      },

      startNextUpload() {

        if (this.uploadProgresses == null || this.currentUpload < 0 || this.currentUpload >= this.uploadProgresses.length) {
          return;
        }

        const log = this.$root.log;

        const uploadProgress = this.uploadProgresses[this.currentUpload];

        const formData = new FormData();
        formData.append('folder',this.folder.id);
        formData.append('uploadID',this.folder.uploadUUID);
        formData.append('replace',this.mayDelete);
        formData.append('token',this.uploadToken);
        
        formData.append("file",uploadProgress.file,uploadProgress.filename);

        let req = new Request('/infex/infexFile',{method:"POST",body:formData});

        this.uploadAbortController = new AbortController();
        uploadProgress.progress = 1;

        log.info(this.$t('infex.uploadingFile',
          {file:uploadProgress.filename,folder:this.folder.displayName}));

        uploadProgress.state = "uploading";

        fetch(req,{signal:this.uploadAbortController.signal}).then(
          (resp) => {

            resp.text().then(
              (txt) => {
                if (txt == "<html><body>OK</body></html>") {
                  uploadProgress.progress = 100;
                  uploadProgress.state = "finished";
                  log.ok(this.$t('infex.successfullyUploadedFile',
                    {file:uploadProgress.filename,folder:this.folder.displayName}));
                  console.log("Successfully uploaded file [",uploadProgress.filename,"] to folder [",this.folder.displayName,"].");

                  ++this.currentUpload;
                  if (this.currentUpload >= this.uploadProgresses.length) {
                    this.uploadAbortController = null;
                    this.currentUpload = -1;
                  }
                  this.$nextTick(this.loadFiles);
                }
                else {
                  this.uploadAbortController = null;
                  this.currentUpload = -1;
                  uploadProgress.state = "failed";
                  console.log("Upload to folder [",this.folder.displayName,"] failed by server error [",txt,"].");
                  log.error(this.$t('infex.uploadingFileFailed',
                    {file:uploadProgress.filename,folder:this.folder.displayName}));
                }
              },
              (err) => {
                this.uploadAbortController = null;
                this.currentUpload = -1;
                uploadProgress.state = "failed";
                
                console.log("Upload to folder [",this.folder.displayName,"] failed by transport error:",err);
                log.error(this.$t('infex.uploadingFileFailed',
                  {file:uploadProgress.filename,folder:this.folder.displayName}));
              }
            );
            
          },
          (err) => {
            this.uploadAbortController = null;
            this.currentUpload = -1;
            uploadProgress.state = "failed";
            console.log("Upload to folder [",this.folder.displayName,"] failed:",err);
            log.error(this.$t('infex.uploadingFileFailed',
              {file:uploadProgress.filename,folder:this.folder.displayName}));
          }
        );
        setTimeout(this.queryProgress.bind(this),1000);
      },
      queryProgress() {

        this.$root.infexService.getUploadProgress(this.folder.id,this.folder.uploadUUID).then(
          (res) => {

            if (res) {
              this.uploadProgresses[this.currentUpload].progress = 100*res.written/res.sizeEst;
            }

            if (this.uploadAbortController != null) {
              setTimeout(this.queryProgress.bind(this),1000);
            }
          },
          (err) => {
            console.log("getUploadProgress() failed :",err);
            this.$root.log.warn(this.$t('uploadProgressFailed'))
          }
        );
      }
    }
  }
</script>
