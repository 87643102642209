export default function(url,options) {
  if (options.reloginHandler) {
    return new Promise( (resolve,reject) => {

      fetch(url,options).then(
        (resp) => {
          if (resp.status == 401 && resp.headers.get("x-http-util-login-url") != null) {
            const loginUrl = resp.headers.get("x-http-util-login-url");
            console.log("Trying to login with URL [",loginUrl,"] fetchning URL [",url,"].");
            
            options.reloginHandler(loginUrl).then(
              (user) => {
                
                console.log("Re-fetching URL [",url,"] after successfuly login of [",user,"].");
                fetch(url,options).then(resolve,reject);
              },
              (err) => {
                console.log("Error during login fetching URL [",url,"]:",err);
                reject(err);
              }
            );
          }
          else {
            resolve(resp);
          }
        },
        (err) => {
          console.log("Fetchinfg URL [",url,"] failed:",err);
          reject(err);
        }
      )
    });
  }
  else {
    return fetch(url,options);
  }
}
