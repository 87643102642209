class JsonRPC {

  constructor (smd,reloginHandler=null) {

    console.log("Initializing JsonRPC Service [",smd.target,"] with methods [",Object.keys(smd.services).join(','),"].");

    this._smd = smd;
    for (let f in smd.services) {
      this[f] = (...args) => this._call(f,args);
    }
    this._reloginHandler=reloginHandler;
    this._lastId = 0;
  }

  _callRaw(id,method,params) {

    return fetch(this._smd.target,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "id": id,
        "jsonrpc": "2.0",
        "method": method,
        "params": params
      })
    }).then(response => response.json());
  }

  _checkResponse(id,method,resp,resolve,reject,allowRelogin) {

    if (resp.jsonrpc != "2.0") {
      
      console.log("Method [",method,"] on [",this._smd.target,"] returned response with wrong protocol [",resp.jsonrpc,"].");
      reject({"code":-32603,
              "message":"Method ["+method+"] on ["+this._smd.target+"] returned response with wrong protocol ["+resp.jsonrpc+"]."
             });
    }

    if (resp.id != id) {
      console.log("Method [",method,"] on [",this._smd.target,"] returned response with wrong ID [",resp.id,"].");
      reject({"code":-32603,
              "message":"Method ["+method+"] on ["+this._smd.target+"] returned response with wrong ID ["+resp.id+"]."
             });
    }

    if (resp.result !== undefined) {
      resolve(resp.result);
    }
    else if (resp.error !== undefined) {

      if (allowRelogin &&
          resp.error.code == -32099 &&
          resp.error.data !== undefined &&
          typeof(resp.error.data.loginUrl) == 'string') {
        return resp.error.data.loginUrl;
      }

      console.log("Method [",method,"] on [",this._smd.target,"] returned error [",resp.error,"].");
      reject(resp.error);
    }
    else {
      console.log("Method [",method,"] on [",this._smd.target,"] returned response without result and error.");
      reject({"code":-32603,
              "message":"Method ["+method+"] on ["+this._smd.target+"] returned response without result and error."
             });
    }
  }
  
  _call(method,params) {
5
    return new Promise( (resolve,reject) => {

      const id = ++this._lastId;
      
      this._callRaw(id,method,params).then(
        (resp) => {
          const loginUrl = this._checkResponse(id,method,resp,resolve,reject,this._reloginHandler!=null);

          if (loginUrl) {

            console.log("Trying to login with URL [",loginUrl,"] invoking method [",method,"] on [",this._smd.target,"]");
            
            this._reloginHandler(loginUrl).then(
              (user) => {
                
                console.log("Re-invoking method [",method,"] on [",this._smd.target,"] after successfuly login of [",user,"].");

                const id = ++this._lastId;

                this._callRaw(id,method,params).then(
                  (resp) => {
                    this._checkResponse(id,method,resp,resolve,reject,false);
                  },
                  (err) => {
                    console.log("Transport error re-invoking method [",method,"] on [",this._smd.target,"]:",err);
                    reject(err);
                  }
                );
              },
              (err) => {
                console.log("Error during login invoking method [",method,"] on [",this._smd.target,"]:",err);
                reject(err);
              }
            );
          }
        },
        (err) => {
          console.log("Transport error invoking method [",method,"] on [",this._smd.target,"]:",err);
          reject(err);
        }
      );
    });    
  }

  static initDeferred(url,reloginHandler=null) {

    return fetch(url + "?smd")
      .then(response => response.json())
      .then(smd => new JsonRPC(smd,reloginHandler));
  }

}

export default JsonRPC;
