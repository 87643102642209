<template>
  <v-container>
    <v-data-table
      dense
      disable-pagination
      :headers="headers"
      :items="folders"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click="editItem(item)"
          color="black"
          :title="$t('infex.admin.editFolder')+'\u2026'"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
          color="black"
          :title="$t('infex.admin.deleteFolder')+'\u2026'"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:body.append>
        <tr>
          <td class="text-start">
            <v-btn icon small @click="refresh" :title="$t('infex.refresh')+'\u2026'">
              <v-icon small color="black">mdi-refresh</v-icon>
            </v-btn>
          </td>
          <td colspan="5" class="text-right">
            <v-btn
              color="primary"
              dark
              @click="addItem()"
            >
              {{ $t('infex.admin.addFolder') }}
              <v-icon
               small
               class="ml-2"
              >
                mdi-folder-plus
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{$t('infex.deleteConfirmation')}}</v-card-title>
        <v-card-text>
          {{$t('infex.admin.reallyDeleteFolder',[editedItem.displayName])}}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{$t('infex.cancel')}}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDelete">{{$t('infex.ok')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialogEdit"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t(editedItem.id == null ? 'infex.admin.addFolder' : 'infex.admin.editFolder') }}</span>
        </v-card-title>

        <v-card-text>
          <v-form v-model="dialogEditValid">
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                    v-model="editedItem.relativePath"
                    :label="$t('infex.admin.relativePath')"
                    :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                    v-model="editedItem.displayName"
                    :label="$t('infex.name')"
                    :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                    v-model="editedItem.comment"
                    :label="$t('infex.comment')"
                    :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                    type="number"
                    v-model.number="editedItem.individualMB"
                    :label="$t('infex.admin.individualMB')"
                    :rules="[rules.required]"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                    type="number"
                    v-model.number="editedItem.totalMB"
                    :label="$t('infex.admin.totalMB')"
                    :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeEdit"
          >
            {{$t('infex.cancel')}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmEdit"
            :disabled="!dialogEditValid"
          >
            {{$t('infex.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
  import {makeInputRules} from '../../lib/Util'

  export default {
    name: 'FoldersPane',

    props: {
      folders: {
        type: Array,
        default: () => []
      }
    },


    data: function() { return {
      headers: [
        {
          text: this.$t('infex.admin.relativePath'),
          align: 'start',
          value: 'relativePath',
        },
        {
          text: this.$t('infex.name'),
          align: 'start',
          value: 'displayName',
        },
        {
          text: this.$t('infex.comment'),
          align: 'start',
          value: 'comment',
        },
        {
          text: this.$t('infex.admin.individualMB'),
          align: 'start',
          value: 'individualMB',
        },
        {
          text: this.$t('infex.admin.totalMB'),
          align: 'start',
          value: 'totalMB',
        },
        { text: this.$t('infex.actions'), value: 'actions', sortable: false },
      ],
      editedItem: {
        id: null,
        relativePath: '',
        displayName: '',
        comment: '',
        individualMB: 128,
        totalMB: 1024,
      },
      defaultItem: {
        id: null,
        relativePath: '',
        displayName: '',
        comment: '',
        individualMB: 128,
        totalMB: 1024,
      },
      dialogEdit: false,
      dialogEditValid: false,
      dialogDelete: false,
      rules: makeInputRules(this),
    }},

    watch: {
      dialogEdit (val) {
        val || this.closeEdit()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    mounted: function() {
      this.$emit("loadFolders",false);
    },

    methods: {
      refresh() {
        this.$emit("loadFolders",true);
      },

      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogEdit = true
      },

      addItem () {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialogEdit = true
      },

      confirmEdit () {

        const log = this.$root.log;

        const op = this.editedItem.id == null ? "createFolder" : "updateFolder"

        this.$root.infexService[op](this.editedItem).then(
          (res) => {
            console.log("FoldersPane: "+op+"() returned [",res,"]");

            this.$nextTick(() => this.$emit("loadFolders",true));
          },
          (err) => {
            console.log("FoldersPane: "+op+"() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:op+"()"}));
          }
        );

        this.closeEdit()
      },

      closeEdit () {
        this.dialogEdit = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },

      deleteItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      confirmDelete () {

        const log = this.$root.log;

        this.$root.infexService.deleteFolder(this.editedItem).then(
          (res) => {
            console.log("FoldersPane: deleteFolder() returned [",res,"]");

            this.$nextTick(() => this.$emit("loadFolders",true));
          },
          (err) => {
            console.log("FoldersPane: deleteFolder() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:"deleteFolder()"}));
          }
        );

        this.closeDelete()
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },
    }
  }
</script>
