/**
 * A flag designating, that the participant has read (download) permissions
 * on the folder.
 */
export const READ_FLAG = 1;
/**
 * A flag designating, that the participant has write (upload) permissions
 * on the folder.
 */
export const WRITE_FLAG = 2;

/**
 * A flag designating, that the participant has permissions to
 * delete or overwrite existing files upon upload operations.
 */
export const DELETE_FLAG = 4;

/**
 * A flag designating, that the participant receives read (download)
 * notification emails from the folder.
 */
export const READ_NOTIFICATION_FLAG = 8;

/**
 * A flag designating, that the participant receives write (upload)
 * notification emails from the folder.
 */
export const WRITE_NOTIFICATION_FLAG = 16;

/**
 * A flag designating, that the participant may generate one-time
 * URL for files in the folder.
 */
export const GENERATE_ONETIMEURLS_FLAG = 32;
