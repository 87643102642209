import de from 'vuetify/lib/locale/de'

export default {
  $vuetify: de,
  infex: {
    ok: "OK",
    save: "Speichern",
    cancel: "Abbrechen",
    refresh: "Aktualisieren",
    name: "Name",
    email: "E-Mail Adresse",
    comment: "Kommentar",
    folder: "Verzeichnis",
    expiry: "Ablaufdatum",
    expiryTime: "Ablaufzeit",
    downloadCount: "Download Zähler",
    maxDownloads: "Max Downloads",
    deleteExpiredFile: "Nach Ablauf löschen",
    deleteDownloadedFile: "Nach Max Downloads löschen",
    initializingInfex: "Initialisierung der infex-Applikation\u2026",
    successfullyInitializedInfex: "Die infex-Applikation wurde erfolgreich initialisiert.",
    checkingLoginUser: "Überprüfung des angemeldeten Benutzers\u2026.",
    checkingLoginUserFailed: "Die Überprüfung des angemeldeten Benutzers ist fehlgeschlagen.",
    loggedOnAs: "Angemeldet als [{0}]",
    loginHeader: "Anmeldung",
    logout: "Abmelden",
    notRegisteredHeader: "Benutzer nicht für InFEX registriert",
    notRegisteredBody: "Der angemeldete Benutzer ist nicht für die infex Applikation registriert, bitte kontaktieren Sie das Administration-Team.",
    administration: "Administration",
    about: "Über\u2026",
    loadingFolders: "Laden der Verzeichnisse\u2026",
    successfullyLoadedFolders: "Es wurden keine Verzeichnisse geladen. | Es wurde ein Verzeichnis geladen. | Es wurden [{n}] Verzeichnisse geladen.",
    loadingFoldersFailed: "Das Laden der Verzeichnisse ist fehlgeschlagen.",
    loadingFiles: "Laden der Dateien des Verzeichnisses [{folder}]\u2026",
    successfullyLoadedFiles: "Es wurde keine Datei im Verzeichnis [{folder}] geladen. | Es wurde eine Datei im Verzeichnis [{folder}] gleaden. | Es wurden [{n}] Dateien im Verzeichnis [{folder}] geladen.",
    loadingFilesFailed: "Das Laden der Dateien des Verzeichnisses [{folder}] ist fehlgeschlagen.",    
    checkingFolder: "Überprüfe Upload-Verzeichnis [{folder}]\u2026",
    successfullyCheckedFolder: "Überprüfung des Upload-Verzeichnisses [{folder}] war erfolgreich.",
    checkingFolderFailed: "Überprüfung des Upload-Verzeichnisses [{folder}] ist fehlgeschlagen.",
    dragYourFiles: 'Legen Sie hier Ihre Dateien ab.',
    filename: "Dateiname",
    filesize: "Größe",
    filedate: "Datum",
    actions: "Aktionen",
    fileDeleteTitle: "Datei [{0}] löschen\u2026",
    fileSendTitle: "Datei [{0}] senden\u2026",
    fileShowTitle: "Datei [{0}] anzeigen\u2026",
    totalFiles: "Kein Dateien | Eine Datei | {n} Dateien",
    totalSize: "Gesamt {0}",
    remainingSize: "{0} verbleibend",
    editTextFile: "Datei `{0}` bearbeiten",
    loadingTextFile: "Laden der Textdatei [{0}]\u2026",
    successfullyLoadedTextFile: "Textdatei [{0}] wurde erfolgreich geladen.",
    loadingTextFileFailed: "Laden der Textdatei [{0}] ist fehlgeschlagen.",
    deleteConfirmation: "Löschbestätigung",
    reallyDeleteFile: "Wollen Sie die Datei {0} wirklich löschen?",
    deletingFile: "Löschen der Datei [{file}] aus dem Verzeichnis [{folder}]\u2026",
    successfullyDeletedFile: "Die Datei [{file}] wurde erfolgreich aus dem Verzeichnis [{folder}] gelöscht.",
    deletingFileFailed: "Das Löschen der Datei [{file}] aus dem Verzeichnis [{folder}] ist fehlgeschlagen.",
    uploadedFileTooLarge: "Die Größe [{size}] der Datei [{file}] übersteigt die maximal Upload-Größe [{maxSize}] des Verzeichnisses [{folder}].",
    uploadedFilesTooLarge: "Die Größe [{size}] keiner Datei übersteigt den verbleibenden Platz [{remaining}] imVerzeichnis [{folder}]. | Die Größe [{size}] der Datei [{files}] übersteigt den verbleibenden Platz [{remaining}] imVerzeichnis [{folder}]. | Die Gesamtgröße [{size}] der Dateien [{files}] übersteigt den verbleibenden Platz [{remaining}] im Verzeichnis [{folder}].",
    uploadingFile: "Hochladen der Datei [{file}] auf das Verzeichnis [{folder}]\u2026",
    successfullyUploadedFile: "Die Datei [{file}] wurde erfolgreich auf das Verzeichnis [{folder}] hochgleaden.",
    uploadingFileFailed: "Fehler beim Hochladen der Datei [{file}] auf das Verzeichnis [{folder}].",
    uploadProgressFailed: "Fehler beim Abfragen des Upload-Fortschritts.",
    input: {
      required: "Mussfeld.",
      invalidEMail: "Ungültige e-mail Adresse."
    },
    permissions: {
      R: "Lesen",
      W: "Schreiben",
      D: "Löschen",
      RN: "Lesebenachrichtigung",
      WN: "Schreibbenachrichtigung",
      OT: "Einmal-URL erzeugen"
    },
    admin: {
      usersTab: "Benutzer",
      participantsTab: "Teilnehmer",
      foldersTab: "Verzeichnisse",
      onetimeUrlsTab: "Einmal-URLs",
      participantPathsTab: "Zuordnung Teilnehmer\u2192Pfade",
      pathParticipantsTab: "Zuordnung Pfad\u2192Teilnhemer",
      operationFailed: "Die Operation [{op}] ist fehlgeschlagen.",
      principal: "Principal",
      registered: "Registriert",
      participant: "Teilnehmer",
      editUser: "Benutzer bearbeiten",
      editParticipant: "Teilnehmer bearbeiten",
      addParticipant: "Neuer Teilnehmer",
      deleteParticipant: "Teilnehmer löschen",
      reallyDeleteParticipant: "Wollen Sie den Teilnehmer [{0}] wirklich löschen?",
      relativePath: "Relative Path",
      individualMB: "Max Upload (MB)",
      totalMB: "Max Größe (MB)",
      addFolder: "Neues Verzeichnis",
      editFolder: "Verzeichnis bearbeiten",
      deleteFolder: "Verzeichnis löschen",
      reallyDeleteFolder: "Wollen Sie das Verzeichnis [{0}] wirklich löschen?",
      addOnetimeUrl: "Neue Einmal-URL",
      editOnetimeUrl: "Einmal-URL bearbeiten",
      deleteOnetimeUrl: "Einmal-URL löschen",
      reallyDeleteOnetimeUrl: "Wollen Sie die Einmal-URL [{0}] wirklich löschen?",
      addPermission: "Neue Berechtigung",
      editPermission: "Berechtigung bearbeiten",
      deletePermission: "Berechtigung löschen",
      reallyDeletePermission: "Wollen Sie die Berechtigung [{0}] wirklich löschen?",
    }
  }
}
