<template>
<div class="d-flex flex-nowrap">
<v-icon>{{iconsForPriority[message.priority]}}</v-icon><span class="grey--text text--darken-2 pl-2 text-no-wrap">{{formatTimestamp(message.timestamp)}}</span><v-spacer class="text-truncate d-inline-block pl-2">{{message.message}}</v-spacer>
</div>
</template>

<script>
export default {
  name: 'LogMessage',

  props: {
    msg: { 
      type: Object,
      default: function () {
        return {
          timestamp: new Date(),
          priority: "INFO",
          message: ""
        }
      }
    },
  },
  
  data: function() {
    return {
      message: this.msg,
      defaultPriority: "INFO",
      iconsForPriority: {
        "OK":   "mdi-checkbox-marked-circle-outline",
        "DEBUG": "mdi-bug-outline",
        "INFO": "mdi-information-outline",
        "WARN": "mdi-alert-outline",
        "ERROR": "mdi-alert-circle-outline",
        "FATAL": "mdi-flash-alert-outline"
      }
    }
  },

  methods: {
    appendMessage(m) {
      const cm = {};
      if (typeof m == "string") {
        cm.message = m;
      }
      else {
        cm.message = m.message;
        cm.priority = m.priority;
        cm.timestamp = m.timestamp;
      }
      if (!cm.priority) {
         cm.priority = this.defaultPriority;
      }
      if (!cm.timestamp) {
         cm.timestamp = new Date();
      }
      this.message=cm;
      this.$emit('newMessage',cm);
    },
    ok(m) {
      this.appendMessage({"priority":"OK","message":m});
    },
    info(m) {
      this.appendMessage({"priority":"INFO","message":m});
    },
    warn(m) {
      this.appendMessage({"priority":"WARN","message":m});
    },
    error(m) {
      this.appendMessage({"priority":"ERROR","message":m});
    },
    fatal(m) {
      this.appendMessage({"priority":"FATAL","message":m});
    },
    formatTimestamp(d) {
      return (d.getYear()+1900).toString().padStart(4,0) + "-" + 
             (d.getMonth()+1).toString().padStart(2,0) + "-" +
             (d.getDate()+1).toString().padStart(2,0) + " " +
             d.getHours().toString().padStart(2,0) + ":" +
             d.getMinutes().toString().padStart(2,0) + ":" +
             d.getSeconds().toString().padStart(2,0) + "." +
             d.getMilliseconds().toString().padStart(3,0);
    }
  }
}
</script>
