<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-1 font-weight-bold mb-3">
          infex v{{version}}
        </h1>

        <p class="subheading font-weight-regular">
          ITEG Next Generation File EXchange<br/>
          &copy; <a href="https://www.iteg.at">ITEG IT-Engineers GmbH</a> {{from}}&ndash;{{to}}
        </p>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    props: [ 'version','from','to' ],
  }
</script>
