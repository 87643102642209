import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

new Vue({
  i18n,
  vuetify,
  render: h => h(App),
  // variables initialized in App.mounted()
  data: () => ({
    log: null,
    checkLoginSvc: null,
    infexService: null
  })
}).$mount('#app')
