import en from 'vuetify/lib/locale/en'

export default {
  $vuetify: en,
  infex: {
    ok: "OK",
    save: "Save",
    cancel: "Cancel",
    refresh: "Refresh",
    name: "Name",
    email: "E-Mail Address",
    comment: "Comment",
    folder: "Folder",
    expiry: "Expiry",
    expiryTime: "Expiry Time",
    downloadCount: "Download Count",
    maxDownloads: "Max Downloads",
    deleteExpiredFile: "Delete on Expiry",
    deleteDownloadedFile: "Delete on Max Download",
    initializingInfex: "Initializing infex\u2026",
    successfullyInitializedInfex: "Successfully Initialized infex.",
    checkingLoginUser: "Checking logged on user\u2026.",
    checkingLoginUserFailed: "Checking logged on user failed.",
    loggedOnAs: "Logged on as [{0}]",
    loginHeader: "Login",
    logout: "Logout",
    notRegisteredHeader: "User not registered for InFEX",
    notRegisteredBody: "The user you are using is not registered for the infex application, please contact the administrator.",
    administration: "Administration",
    about: "About\u2026",
    loadingFolders: "Loading folders\u2026",
    successfullyLoadedFolders: "No folder has been loaded. | One folder has been loaded. | Successfully loaded [{n}] folders.",
    loadingFoldersFailed: "Loading folders failed.",
    loadingFiles: "Loading files for folder [{folder}]\u2026",
    successfullyLoadedFiles: "No file has been loaded for folder [{folder}]. | One file has been loaded for folder [{folder}]. | Successfully loaded [{n}] files for folder [{folder}].",
    loadingFilesFailed: "Loading files for folder [{folder}] failed.",
    checkingFolder: "Checking upload folder [{folder}]\u2026",
    successfullyCheckedFolder: "Successfully checked upload folder [{folder}].",
    checkingFolderFailed: "Check of upload folder [{folder}] failed.",
    dragYourFiles: 'Drag your files here.',
    filename: "Filename",
    filesize: "Size",
    filedate: "Date",
    actions: "Actions",
    fileDeleteTitle: "Delete File [{0}]\u2026",
    fileSendTitle: "Send File [{0}]\u2026",
    fileShowTitle: "Show File [{0}]\u2026",
    totalFiles: "No Files | One File | {n} Files",
    totalSize: "Total {0}",
    remainingSize: "{0} remaining",
    editTextFile: "Edit file `{0}`",
    loadingTextFile: "Loading text file [{0}]\u2026",
    successfullyLoadedTextFile: "Successfully loaded text file [{0}].",
    loadingTextFileFailed: "Loading text file [{0}] ist failed.",
    deleteConfirmation: "Delete Confirmation",
    reallyDeleteFile: "Do you really want to delete file [{0}]?",
    deletingFile: "Deleting file [{file}] from folder [{folder}]\u2026",
    successfullyDeletedFile: "Successfully deleted file [{file}] from folder [{folder}].",
    deletingFileFailed: "Deleting file [{file}] from folder [{folder}] failed.",
    uploadedFileTooLarge: "Size [{size}] of file [{file}] exceeds maximal upload size [{maxSize}] of folder [{folder}].",
    uploadedFilesTooLarge: "Size [{size}] of no file exceeds remaining space [{remaining}] of folder [{folder}]. | Size [{size}] of file [{files}] exceeds remaining space [{remaining}] of folder [{folder}]. | Total size [{size}] of files [{files}] exceeds remaining space [{remaining}] of folder [{folder}].",
    uploadingFile: "Uploading file [{file}] to folder [{folder}]\u2026",
    successfullyUploadedFile: "Successfully uploaded file [{file}] to folder [{folder}].",
    uploadingFileFailed: "Upload of file [{file}] to folder [{folder}] failed.",
    uploadProgressFailed: "Fetching upload progress failed.",
    input: {
      required: "Required.",
      invalidEMail: "Invalid e-mail address."
    },
    permissions: {
      R: "Read",
      W: "Write",
      D: "Delete",
      RN: "Read Notification",
      WN: "Write Notification",
      OT: "Create Oone-Time URL"
    },
    admin: {
      usersTab: "Users",
      participantsTab: "Participants",
      foldersTab: "Folders",
      onetimeUrlsTab: "One-Time URLs",
      participantPathsTab: "Participant\u2192Paths Associations",
      pathParticipantsTab: "Path\u2192Participants Associations",
      operationFailed: "Operation [{op}] failed.",
      principal: "Principal",
      registered: "Registered",
      participant: "Participant",
      editUser: "Edit User",
      editParticipant: "Edit Participant",
      addParticipant: "Add Particpant",
      deleteParticipant: "Delete Particpant",
      reallyDeleteParticipant: "Do you really want to delete participant [{0}]?",
      relativePath: "Relative Path",
      individualMB: "Max Upload (MB)",
      totalMB: "Max Size (MB)",
      addFolder: "Add Folder",
      editFolder: "Edit Folder",
      deleteFolder: "Delete Folder",
      reallyDeleteFolder: "Do you really want to delete folder [{0}]?",
      addOnetimeUrl: "Add One-Time URL",
      editOnetimeUrl: "Edit One-Time URL",
      deleteOnetimeUrl: "Delete One-Time URL",
      reallyDeleteOnetimeUrl: "Do you really want to delete One-Time URL [{0}]?",
      addPermission: "Add Permission",
      editPermission: "Edit Permission",
      deletePermission: "Delete Permission",
      reallyDeletePermission: "Do you really want to delete Permission [{0}]?",
    }
  }
}
