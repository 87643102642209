<template>
  <v-dialog
   v-model="isActive"
   content-class="align-self-end"
  >
    <v-card>
      <template v-for="message in messages">
        <div class="d-flex flex-nowrap" :key="message.id">
          <LogMessage :msg="message"/>
        </div>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import Toggleable from 'vuetify/lib/mixins/toggleable'
import LogMessage from './LogMessage';

export default Toggleable.extend({
  name: 'LogMessageHistory',

  components: {
    LogMessage
  },

  props: {
    maxMessages: { 
      type: Number,
      default: 10
    }
  },
  
  data: function() {
    return {
      messages: [],
      lastId: 0
    }
  },

  methods: {
    appendMessage(m) {
      const cm = {};
      if (typeof m == "string") {
        cm.message = m;
      }
      else {
        cm.message = m.message;
        cm.priority = m.priority;
        cm.timestamp = m.timestamp;
      }
      if (!cm.priority) {
         cm.priority = this.defaultPriority;
      }
      if (!cm.timestamp) {
         cm.timestamp = new Date();
      }

      if (this.messages.length >= this.maxMessages) {
        this.messages.splice(0,this.messages.length+1-this.maxMessages);
      }
      cm.id = ++this.lastId;
      this.messages.push(cm);
      
      this.$emit('newMessage',cm);
    },
    ok(m) {
      this.appendMessage({"priority":"OK","message":m});
    },
    info(m) {
      this.appendMessage({"priority":"INFO","message":m});
    },
    warn(m) {
      this.appendMessage({"priority":"WARN","message":m});
    },
    error(m) {
      this.appendMessage({"priority":"ERROR","message":m});
    },
    fatal(m) {
      this.appendMessage({"priority":"FATAL","message":m});
    }
  }
})
</script>
