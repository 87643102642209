<template>
  <v-container>
    <v-data-table
      dense
      disable-pagination
      :headers="headers"
      :items="users"
      :key="participantsMapKey"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.registered="{ item }">
        <span>{{ formatTimestamp(new Date(item.registered))}}</span>
      </template>
      <template v-slot:item.participantId="{ item }">
        <span>{{ formatParticipantId(item.participantId) }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          color="black"
          :title="$t('infex.admin.editUser')+'\u2026'"
          @click="editItem(item)"
        >
          mdi-account-edit
        </v-icon>
      </template>
      <template
        v-slot:footer
      >
        <div style="margin-top:0px; height: 0px; position: relative;">
          <v-btn icon small @click="refresh" :title="$t('infex.refresh')+'\u2026'" style="position:absolute; left:8px; top:16px;">
            <v-icon small color="black">
              mdi-refresh
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <v-dialog
        v-model="dialogEdit"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('infex.admin.editUser') }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                {{editedItem.principal}}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                    v-model="editedItem.participantId"
                    :items="participants"
                    item-text="name"
                    item-value="id"
                    clearable
                    :return-object="false"
                    :label="$t('infex.admin.participant')+'\u2026'"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeEdit"
          >
            {{$t('infex.cancel')}}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="confirmEdit"
          >
            {{$t('infex.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { formatTimestamp,makeMap} from '../../lib/Util'

  export default {
    name: 'UsersPane',

    props: {
      users: {
        type: Array,
        default: () => []
      },
      participants: {
        type: Array,
        default: () => []
      }
    },

    data: function() { return {
      headers: [
        {
          text: this.$t('infex.admin.principal'),
          align: 'start',
          value: 'principal',
        },
        { text: this.$t('infex.admin.registered'), value: 'registered' },
        { text: this.$t('infex.admin.participant'), value: 'participantId' },
        { text: this.$t('infex.actions'), value: 'actions', sortable: false },
      ],
      participantsMap: {},
      participantsMapKey: 0,
      editedItem: {
        principal: '',
        registered: 0,
        participantId: null
      },
      defaultItem: {
        principal: '',
        registered: 0,
        participantId: null
      },
      dialogEdit: false,
    }},

    watch: {
      participants(val) {
        this.participantsMap=makeMap(val);
        ++this.participantsMapKey;
        console.log("UsersPane: participants changed [",this.participantsMapKey,"]");
      },
      dialogEdit (val) {
        val || this.closeEdit()
      },

    },

    mounted: function() {
      this.$emit("loadUsers",false);
      this.$emit("loadParticipants",false);
      this.participantsMap=makeMap(this.participants);
    },

    methods: {
      formatTimestamp,

      refresh() {
        this.$emit("loadUsers",true);
      },

      formatParticipantId(pid) {
        if (pid == null) {
          return '\u2014';
        }
        else {
          const p = this.participantsMap[pid];
          if (p == null) {
            return "#" + pid;
          }
          else {
            return p.name;
          }
        }
      },
      editItem (item) {
        this.editedItem = Object.assign({}, item)
        this.dialogEdit = true
      },

      confirmEdit () {

        const log = this.$root.log;

        this.$root.infexService.associateUserWithParticipant(this.editedItem.id,this.editedItem.participantId).then(
          (res) => {
            console.log("UserPane: associateUserWithParticipant() returned [",res,"]");

            this.$nextTick(() => this.$emit("loadUsers",true));
          },
          (err) => {
            console.log("UserPane: associateUserWithParticipant() failed:",err);
            log.error(this.$t('infex.admin.operationFailed',{op:"associateUserWithParticipant()"}));
          }
        );

        this.closeEdit()
      },

      closeEdit () {
        this.dialogEdit = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
        })
      },

    }
  }
</script>
