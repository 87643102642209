<style>
.clazzesLoginFrame {
  border: 0px;
  width: 100%;
  height: 12em;
}
</style>

<template>
   <v-dialog
    v-model="shown"
    persistent
    max-width="450"
  >
    <v-card>
      <v-card-title class="headline">
        {{$t('infex.loginHeader')}}
      </v-card-title>
      <v-card-text><iframe class="clazzesLoginFrame" :src="loginUrl" @load="onFrameLoad"></iframe></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoginDialog',

  data: () => ({
    loginUrl: '',
    shown: false,
    loginPromises: []
  }),

  methods: {
    onFrameLoad(event) {
      try {
        const doc = event.target.contentWindow.document;

        var form = doc.getElementById('loginResultForm');

        if (form) {
          const status = Number(form.status.value);   
          if (status == 200) {
             
             for (let p in this.loginPromises) {
               try {
                 this.loginPromises[p].resolve(form.principal.value);
               }
               catch(err) {
                 console.log("Warning Error resolving login promise [",p,"]:",err);
               }
             }
             this.loginPromises = [];
             this.loginUrl = '';
             this.shown = false;
          }
          else if (status == 406) {
             for (let p in this.loginPromises) {
               try {
                 this.loginPromises[p].reject("Login failed with status [",status,"].");
               }
               catch(err) {
                 console.log("Warning Error rejecting login promise [",p,"]:",err);
               }
             }
             this.loginPromises = [];
             this.loginUrl = '';
             this.shown = false;
          }
          // maybe we need focus fiddlery here...
        }
      } catch (err) {
         console.log("Error fetching login form status:",err);
      }
    },
    reloginHandler(loginUrl) {
      this.shown = true;
      if (this.loginPromises.length == 0) {
         this.loginUrl = loginUrl + "?locale=" + this.$i18n.locale;
      }

      return new Promise((resolve,reject) => {
        this.loginPromises.push({resolve,reject});
      });
    }
  }
};
</script>
