<template>
  <v-app>
    <v-navigation-drawer app clipped permanent>
      <div class="d-flex flex-column" style="height: 100%;">
      <v-list-item class="flex-grow-0" style="flex-basis: 64px !important;">
        <v-list-item-content>
          <v-list-item-title class="title">
            infex
          </v-list-item-title>
          <v-list-item-subtitle>
            {{appVersion}}
          </v-list-item-subtitle>

        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="fetchLoginInformation" :title="$t('infex.refresh')+'\u2026'">
            <v-icon color="black">mdi-refresh</v-icon>
          </v-btn>
        </v-list-item-action>

      </v-list-item>

      <v-divider></v-divider>

      <v-list-item-group v-model="currentTab" class="overflow-y-auto flex-grow-1" mandatory>
      <v-list
        dense
        nav
      >
      <template v-if="!folders">
        <v-list-item
          link
        >
          <v-list-item-icon>
            <v-progress-circular color="grey lighten-1" size="24" indeterminate/>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>loading...</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item
          v-for="item in folders"
          :key="item.id"
          :value="item.id"
          link
        >
          <v-list-item-icon>
            <v-icon>mdi-folder</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.displayName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      </v-list>

      <v-divider></v-divider>

      <v-list-item
        link key="about"
        value="about"
      >
        <v-list-item-icon>
          <v-icon>mdi-help-box</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{$t('infex.about')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-if="administrator">
        <v-list-item
          link key="administration"
          value="administration"
        >
          <v-list-item-icon>
            <v-icon>mdi-tools</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{$t('infex.administration')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </template>
      </v-list-item-group>
      </div>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="INFEX Logo"
          class="shrink mr-2"
          contain
          :src="require('./assets/infex-header.svg')"
          transition="scale-transition"
          width="320"
          height="47.17"
        />

      </div>

      <v-spacer></v-spacer>

      <span class="mr-2">{{$t('infex.loggedOnAs',[userName])}}</span>

      <v-btn
        @click="onLogout"
        target="_blank"
        text
      >
        <span class="mr-2">{{$t('infex.logout')}}</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-tabs-items v-model="currentTab">
          <v-tab-item
            v-for="item in folders"
            :key="item.id"
            :value="item.id"
            :transition="false"
            :reverse-transition="false"
          >
            <Folder :folder="item" :refreshCount="refreshCount"/>
          </v-tab-item>
          
          <v-tab-item
            key="about"
            value="about"
            :transition="false"
            :reverse-transition="false"
          >
            <About :version="appVersion" from="2012" :to="1900+new Date().getYear()"/>
          </v-tab-item>

          <template v-if="administrator">
            <v-tab-item
              key="administration"
              value="administration"
              :transition="false"
              :reverse-transition="false"
            >
              <Administration v-model="adminTab"/>
            </v-tab-item>
          </template>
        </v-tabs-items>
      </v-container>
    </v-main>

    <v-footer app padless class="text-no-wrap flex-nowrap" @click.native="openLogHistory">
      <LogMessage ref="log" class="flex-grow-1" @newMessage="appendMessageToHistory"/>
      <v-icon>{{logHistoryShown ? 'mdi-chevron-down' : 'mdi-chevron-up'}}</v-icon>
    </v-footer>
    <LoginDialog ref="loginDialog"/>
    <LogMessageHistory ref="logHistory" v-model="logHistoryShown"/>
    <v-dialog
      v-model="userNotRegistered"
      persistent
      max-width="480"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{$t('infex.notRegisteredHeader')}}
        </v-card-title>
        <v-card-text>{{$t('infex.notRegisteredBody')}}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            @click="onLogout"
            target="_blank"
            text
            >
            <span class="mr-2">{{$t('infex.logout')}}</span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import About from './components/About';
import Administration from './components/Administration';
import Folder from './components/Folder';
import LogMessage from './lib/LogMessage';
import LogMessageHistory from './lib/LogMessageHistory';
import LoginDialog from './lib/LoginDialog';
import JsonRPC from './lib/JsonRPC';

export default {
  name: 'App',

  components: {
    Administration,
    Folder,
    About,
    LoginDialog,
    LogMessage,
    LogMessageHistory
  },

  mounted: function() {
    const log = this.$refs.log;
    this.$root.log = log;
    console.log("App mounted.");

    const reloginHandler = this.$refs.loginDialog.reloginHandler;

    log.info(this.$t('infex.initializingInfex'));
    Promise.all([
      JsonRPC.initDeferred("/infex/checkLogin",reloginHandler),
      JsonRPC.initDeferred("/infex/infexService",reloginHandler)    
    ]).then(
        (svcs) => {
          log.ok(this.$t('infex.successfullyInitializedInfex'));
          
          this.$root.checkLoginSvc = svcs[0];
          this.$root.infexService = svcs[1];
          this.$root.reloginHandler = reloginHandler;
          this.fetchLoginInformation();
        }
    );
  },

  data: () => ({
    userName: '\u2014',
    appVersion: '\u2014',
    administrator: false,
    participant: { id: -1, name: '\u2014' },
    logHistoryShown: false,
    folders: null,
    currentTab: "about",
    adminTab: "participants",
    refreshCount: 0
  }),

  computed: {
    userNotRegistered() {
      return this.participant == null;
    }
  },
  
  watch: {
    currentTab(val) {
      if (val == null) {
        return;
      }
      console.log("Current Tab changed to [",val,"].");
      let hash = '';
      if (val == 'administration') {
         hash = '#administration=' + this.adminTab;
      }
      else if (val == 'about') {
         hash = '#about';
      }
      else if (typeof val == "number") {
         hash = '#folder=' + val;
      }

      if (hash != location.hash) {
        location.hash = hash;
      }
    },
    adminTab(val) {
      console.log("Admin Tab changed to [",val,"].");
      if (this.currentTab == "administration") {
        const hash = '#administration=' + this.adminTab;
        if (hash != location.hash) {
          location.hash = hash;
        }
      }
    },
  },


  methods: {
    fetchLoginInformation() {
      const log = this.$root.log;
      log.info(this.$t('infex.checkingLoginUser'));

      this.$root.checkLoginSvc.getLoginInformation().then(
        (res) => {
          this.userName = res.principal;
          this.appVersion = res.applicationVersion;
          this.administrator = res.administrator;          
          this.participant = res.participant;
          log.ok(this.$t('infex.loggedOnAs',[res.principal]));
          console.log("checkLogin() returned [",res,"]");

          if (res.participant) {
            this.loadFolders();
          }
        },
        (err) => {
          console.log("checkLogin() failed [",err,"]");
          log.error(this.$t('infex.checkingLoginUserFailed'));
        }
      );
    },
    setHash() {
      console.log('The hash changed to [',location.hash,']');
      if (location.hash.startsWith("#")) {
        var searchParams = new URLSearchParams(location.hash.substring(1));
        if (searchParams.get("about") === '') {
          this.currentTab = "about";
        }
        else if (searchParams.get("folder")) {
          this.currentTab = Number(searchParams.get("folder"));

          if (searchParams.get("action") == "download" && searchParams.get("filename") != null) {
             window.open("/infex/infexFile/"+searchParams.get("folder")+"/"+encodeURI(searchParams.get("filename")));
          }
          location.hash = "#folder="+searchParams.get("folder");
        }
        else if (searchParams.get("administration")) {
          const at = Number(searchParams.get("administration"));
          if (at >= 0 && at <= 5) {
            this.adminTab = at;
            this.currentTab = "administration";
          }
        }
        
      }
    },
    loadFolders() {

      ++this.refreshCount;
      const log = this.$root.log;
      log.info(this.$t('infex.loadingFolders'));

      this.$root.infexService.getFoldersOfParticipant(this.participant.id).then(
        (res) => {
          log.ok(this.$tc('infex.successfullyLoadedFolders',res.length,{n:res.length}));
          console.log("getFoldersOfParticipant() returned [",res,"]");
          this.folders = res;
          window.addEventListener('hashchange',this.setHash.bind(this),false);
          this.setHash();
        },
        (err) => {
          console.log("getFoldersOfParticipant() failed [",err,"]");
          log.error(this.$t('infex.loadingFoldersFailed'));
        }
      );
    },
    onLogout() {
      this.$root.checkLoginSvc.logout().then(() => location.reload());
    },
    appendMessageToHistory(msg) {
      this.$refs.logHistory.appendMessage(msg);
    },
    openLogHistory() {
      this.logHistoryShown = true;
    }
  }
};
</script>
