import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from './i18n';

Vue.use(Vuetify);

console.log("Initializing vuetify with i18n locale [",i18n.locale,"].");

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#5e0012",
        secondary: "#c50025",
        accent: "#311B92", // deep-purple darken-4
      },
      dark: {
        primary: "#5e0012",
        secondary: "#c50025",
        accent: "#311B92", // deep-purple darken-4
      },
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
    current: i18n.locale
  },
  i18n,
});
